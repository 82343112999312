.bottom-row-container,
.title-bar {
  display: flex;
  justify-content: space-between;
}

.bottom-row-container {
  align-items: center;
  flex-direction: row-reverse;
  margin-top: 15px;
}

.title-bar {
  align-items: top;

  h1 {
    margin-top: 0.5rem;
  }
}

.toast-center-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.advanced-button {
  font-size: smaller;
}

.advanced-properties {
  border: 1px solid rgb(0 0 0 / 12%);
  border-radius: 5px;

  ul {
    margin-bottom: 3px;
  }
}

#toast-container.toast-container .ngx-toastr {
  b {
    font-weight: 500;
  }

  padding: 15px 20px;
  background-image: none;
  min-width: 300px;
  max-width: 500px;
  width: 33vw;
  background-color: #fff;
  color: #000;
  box-shadow: 0 2px 4px -1px rgb(0 0 0 / 20%), 0 4px 5px 0 rgb(0 0 0 / 14%), 0 1px 10px 0 rgb(0 0 0 / 12%);
}

.error-list,
.advanced-properties {
  margin-top: 15px;

  ul {
    list-style-type: none;
    margin-left: 0;
    padding-left: 15px;
    margin-top: 5px;

    li {
      margin-left: 0;
    }
  }
}

.error-list {
  background-color: #efefef;
  border-radius: 5px;
  padding: 10px 0 1px;

  .error-list-header {
    border-bottom: 5px solid white;
    font-weight: 500;
    padding: 0 0 7px 15px;
  }
}
