// TODO naming subject to change

$hunter-red: #bd3020;
$overlay-color: rgb(0 0 0 / 20%);
$gray_3: #333;
$gray_4: #444;
$gray_5: #555;
$gray_C: #ccc;
$gray_D: #ddd;
$gray_E: #eee;
$card-color: #f9f9f9;
$blue: #5485e5;
$yellow: #f6d83b;
$red: #d64545;
$green: #76c279;
$lighter-blue: #5e94ff;
$lighter-yellow: #f7e39a;
$lighter-red: #ee4d4d;
$lighter-green: #8ccc8f;
